const SIZES = ['sm', 'md', 'lg'];

/**
 * Adds a bootstrap compatible size prop.
 *
 * @type {Object}
 */
export const hasSizeProp = {
  props: {
    size: {
      type: String,
      default: null,
      validator(value) {
        return SIZES.includes(value);
      },
    },
  },
};
