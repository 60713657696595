import { carrierMapping } from '@/helpers/carrierMapping';
import { defineVariant } from '@/service/tailwind/defineVariant';

export const buttonVariant = defineVariant(
  'buttonVariant',
  {
    primary: [
      'hover:tw-bg-primary-600',
      'tw-bg-primary-500',
      'tw-text-foreground',
    ],
    light: [
      'hover:tw-bg-gray-100',
      'tw-bg-white',
      'tw-text-black',
    ],
    danger: [
      'hover:tw-bg-danger-600',
      'tw-bg-danger-500',
    ],
    success: [
      'hover:tw-bg-success-600',
      'tw-bg-success-500',
    ],
    warning: [
      'hover:tw-bg-warning-600',
      'tw-bg-warning-500',
    ],
    info: [
      'hover:tw-bg-info-600',
      'tw-bg-info-500',
    ],
    icon: [
      'hover:tw-bg-gray-100',
      'tw-bg-white',
      'tw-text-black',
      'tw-p-2',
    ],

    // Carriers
    ...carrierMapping.reduce((acc, carrier) => ({
      ...acc,
      [carrier.name]: [
        `tw-bg-${carrier.name}-500`,
        `hover:tw-bg-${carrier.name}-600`,
      ],
    }), {}),
  },
  {
    default: 'primary',
  },
);
