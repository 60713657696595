/**
 * Validates a value is of the given type or null.
 *
 * @param {String} type
 * @returns {Function<Boolean>}
 * @see https://forum.vuejs.org/t/how-to-allow-null-prop/39259
 */
export function createNullPropValidator(type) {
  return (prop) => typeof prop === type || prop === null;
}
