<template>
  <a
    v-test="{ id: 'link' }"
    :href="mutableLink"
    target="_blank"
    :class="`carrier-link link-${carrier.name}`">
    <span class="tw-inline-flex">
      <img
        :src="carrierLogo"
        class="carrier-logo tw-my-auto"
        :alt="$t(`carriers.${carrier.name}.name`)">
      <span
        v-t="{
          path: 'track_trace.carrier_link',
          args: {
            carrier: $t(`carriers.${carrier.name}.name`),
          },
        }"
        class="tw-my-auto" />
    </span>
  </a>
</template>

<script>
import CImage from '@/components/CImage';
import config from '@/helpers/config';
import { mapGetters } from 'vuex';

export default {
  name: 'CarrierLink',
  components: { CImage },
  props: {
    link: {
      default: null,
      type: String,
    },
  },

  data() {
    return {
      mutableLink: this.link,
    };
  },

  computed: {
    ...mapGetters(['currentTrackingInformation']),

    carrier() {
      return this.currentTrackingInformation.carrier;
    },

    carrierLogo() {
      return config.carrierLogo[this.carrier.name];
    },
  },

  created() {
    if (!this.link && this.currentTrackingInformation.link_tracktrace) {
      this.mutableLink = this.currentTrackingInformation.link_tracktrace;
    }
  },
};
</script>
