<template>
  <AddressBlock
    v-if="loadingFields"
    loading />
  <AddressBlock
    v-else-if="senderWithoutPerson"
    :data="senderWithoutPerson" />
  <AddressBlock
    v-else
    :data="fields.company" />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AddressBlock from '@/components/AddressBlock';

export default {
  name: 'SenderAddress',
  components: {
    AddressBlock,
  },

  computed: {
    ...mapState(['loadingFields']),
    ...mapGetters(['currentTrackingInformation', 'fields']),

    /**
     * @returns {?Object}
     */
    senderWithoutPerson() {
      if (!this.currentTrackingInformation.sender) {
        return null;
      }

      const { person, ...senderWithoutPerson } = this.currentTrackingInformation.sender;

      return senderWithoutPerson;
    },
  },
};
</script>
