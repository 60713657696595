<template>
  <transition
    is="div"
    name="fade"
    class="d-flex wrapper"
    appear>
    <iframe
      v-if="isType('iframe') && !error"
      :src="url"
      @load="stopLoading" />

    <div
      v-if="error"
      class="bg-dark d-flex">
      <span
        v-t="'track_trace.social_error'"
        class="m-auto px-3 text-center text-light" />
    </div>

    <a
      v-if="isType('script')"
      :href="url"
      :data-link-color="branding.accent_color"
      target="_blank"
      class="twitter-timeline"
      data-lang="en"
      data-height="512"
      data-dnt="true" />

    <div
      v-if="loadingSocial"
      class="d-flex h-100 position-absolute w-100">
      <Loader class="m-auto" />
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { socialPlatforms } from '@/helpers/socialPlatforms';

export default {
  name: 'SocialWidget',
  data() {
    return {
      error: false,
      loadingSocial: true,
    };
  },
  computed: {
    ...mapState(['branding']),
    ...mapGetters(['fields']),
    socialMap() {
      return {
        twitter_timeline: {
          type: 'script',
          script: 'https://platform.twitter.com/widgets.js',
          url: `${socialPlatforms.twitter}${this.fields.social_widget.identifier}`,
        },
        instagram_post: {
          type: 'iframe',
          url: `${socialPlatforms.instagram}p/${this.fields.social_widget.identifier}/embed/`,
        },
      };
    },
    url() {
      return this.socialMap[this.fields.social_widget.type].url;
    },
    script() {
      return this.socialMap[this.fields.social_widget.type].script;
    },
  },
  async created() {
    this.loadingSocial = true;
    if (this.socialMap[this.fields.social_widget.type].type === 'script') {
      this.importScript();
    } else {
      const iframe = await fetch(this.url);
      if (iframe.status === 404) {
        this.error = true;
        this.stopLoading();
      }
    }
  },
  methods: {
    isType(type) {
      return this.socialMap[this.fields.social_widget.type].type === type;
    },
    stopLoading() {
      this.loadingSocial = false;
    },
    importScript() {
      const el = document.createElement('script');
      el.setAttribute('src', this.script);
      el.addEventListener('load', () => {
        this.stopLoading();
      });

      document.head.appendChild(el);
    },
  },
};
</script>
