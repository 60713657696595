<template>
  <CButton
    :key="icon"
    variant="icon"
    class="tw-leading-5 tw-mr-1"
    :skeleton="skeleton"
    @click="$emit('click')">
    <div
      :class="`i-${icon}`"
      class="tw-h-5 tw-m-auto tw-w-5" />
  </CButton>
</template>

<script>
import CButton from '@/components/CButton';

export default {
  name: 'IconButton',
  components: {
    CButton,
  },

  props: {
    skeleton: {
      type: Boolean,
    },

    icon: {
      type: String,
      required: true,
    },
  },

  emits: ['click'],
};
</script>
