var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({directives:[{name:"test",rawName:"v-test",value:('card'),expression:"'card'"}],staticClass:"card grid-item--static-height text-center tw-bg-primary-500",style:(_vm.bannerBackground)},'div',_vm.attrs,false),[_c(_vm.bannerURL ? 'a' : 'div',{tag:"component",staticClass:"card-body d-flex",class:{
      'bg-filter': _vm.hasBannerImage && (_vm.fields.banner_action_title || _vm.hasButton),
      'text-decoration-none': !!_vm.bannerURL,
      'text-reset': !!_vm.bannerURL,
    },attrs:{"href":_vm.bannerURL || null,"target":_vm.bannerURL ? '_blank' : null}},[_c('div',{staticClass:"m-auto",class:{
        'text-white': _vm.hasBannerImage,
        'text-foreground': !_vm.hasBannerImage,
      }},[(_vm.fields.banner_action_title)?_c('h3',{domProps:{"textContent":_vm._s(_vm.fields.banner_action_title)}}):_vm._e(),(_vm.hasButton)?_c('CButton',{directives:[{name:"t",rawName:"v-t",value:('track_trace.banner_button'),expression:"'track_trace.banner_button'"}],attrs:{"variant":"light","size":"lg"}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }