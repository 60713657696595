export const BREAKPOINT_XS = 1;
export const BREAKPOINT_SM = 2;
export const BREAKPOINT_MD = 3;
export const BREAKPOINT_LG = 4;
export const BREAKPOINT_XL = 5;

/* eslint-disable no-magic-numbers */
const breakpoints = {
  [BREAKPOINT_XS]: 0,
  [BREAKPOINT_SM]: 576,
  [BREAKPOINT_MD]: 768,
  [BREAKPOINT_LG]: 992,
  [BREAKPOINT_XL]: 1200,
};

/**
 * Check the window innerWidth to find the matching breakpoint.
 *
 * @returns {Number}
 *
 * @todo MY-25794 Remove when we go CSS only.
 */
export function getBreakpoint() {
  const matches = Object
    .keys(breakpoints)
    .filter((breakpoint) => {
      return window.innerWidth >= breakpoints[breakpoint];
    })
    .map(Number);

  return Math.max(...matches);
}
