<template>
  <div class="card track-trace__info">
    <TrackTraceStatusHeader />

    <div class="card-body track-trace__info">
      <ShipmentOptionsAlerts />
      <TrackTraceTimeline :expanded="trackingExpanded" />
    </div>

    <CardToggle
      v-if="currentTrackingInformation.hasSubStatuses"
      :toggle.sync="trackingExpanded" />

    <CarrierLink class="card-footer" />
    <ExternalCarrierLink
      v-for="link in currentTrackingInformation.partner_tracktraces"
      :key="link.barcode"
      class="card-footer"
      :link="link.uri" />
  </div>
</template>

<script>
import CardToggle from '@/components/CardToggle';
import CarrierLink from '@/components/tracktrace/CarrierLink';
import ExternalCarrierLink from '@/components/tracktrace/ExternalCarrierLink';
import ShipmentOptionsAlerts from '@/components/tracktrace/ShipmentOptionsAlerts';
import TrackTraceStatusHeader from '@/components/tracktrace/TrackTraceStatusHeader';
import TrackTraceTimeline from '@/components/tracktrace/TrackTraceTimeline';
import { mapGetters } from 'vuex';
import smoothReflow from 'vue-smooth-reflow';

export default {
  name: 'TrackTraceInfo',
  components: {
    TrackTraceStatusHeader,
    CarrierLink,
    ExternalCarrierLink,
    ShipmentOptionsAlerts,
    TrackTraceTimeline,
    CardToggle,
  },

  mixins: [smoothReflow],

  data() {
    return {
      trackingExpanded: false,
    };
  },

  computed: {
    ...mapGetters(['currentTrackingInformation']),
  },

  mounted() {
    this.$smoothReflow();
  },
};
</script>
