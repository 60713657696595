<template>
  <div
    v-test="{ id: 'star-wrapper' }"
    class="d-flex justify-content-center review-stars"
    @mouseout="() => setActive(0)">
    <div
      v-for="index in amount"
      :key="starClass(index) + '_' + index">
      <i
        v-test="{ id: 'star' }"
        :class="starClass(index)"
        class="px-1 star">
        <span
          v-test="{ id: 'star-left' }"
          class="half half--left"
          @mouseover="() => setActive(index - .5)" />
        <span
          v-test="{ id: 'star-right' }"
          class="half half--right"
          @mouseover="() => setActive(index)" />
      </i>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Stars',
  props: {
    amount: {
      default: 5,
      type: Number,
    },
  },

  data() {
    return {
      active: null,
    };
  },

  methods: {
    starClass(index) {
      const HALF_STAR = 0.5;

      if (this.active + HALF_STAR >= index) {
        if (index - HALF_STAR === this.active) {
          return 'i-star-half';
        }

        return 'i-star';
      }

      return 'i-star-o';
    },

    setActive(amount) {
      this.$emit('update', amount);
      this.active = amount;
    },
  },
};
</script>
