<template>
  <div class="card grid-item--static-height">
    <SocialWidget :key="'social_' + $i18n.locale" />
  </div>
</template>

<script>
import SocialWidget from '@/components/tracktrace/SocialWidget';

export default {
  name: 'TrackTraceSocial',
  components: {
    SocialWidget,
  },
};
</script>
