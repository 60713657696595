<template>
  <DelayedTransitionGroup
    tag="div"
    class="status-grid track-trace__timeline"
    name="timeline"
    interval="0">
    <TrackTraceTimelineStatus
      v-for="mainStatus in mainStatuses"
      :key="mainStatus.key"
      :status="mainStatus" />

    <TrackTraceTimelineStatus
      key="currentStatus"
      :status="currentStatus"
      class="current-status"
      status-type="current" />

    <template v-if="futureStatuses">
      <TrackTraceTimelineStatus
        v-for="futureStatus in futureStatuses"
        :key="generateKey(futureStatus)"
        :status="futureStatus"
        status-type="future" />
    </template>
  </DelayedTransitionGroup>
</template>

<script>
import TrackTraceTimelineStatus from '@/components/tracktrace/TrackTraceTimelineStatus';
import config from '@/helpers/config';
import { mapGetters } from 'vuex';

export default {
  name: 'TrackTraceTimeline',
  components: {
    TrackTraceTimelineStatus,
  },
  props: {
    expanded: {
      type: Boolean,
    },
  },
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    ...mapGetters(['currentTrackingInformation']),

    currentStatus() {
      return this.currentTrackingInformation.status;
    },

    /**
     * History filtered by main attribute. Not main statuses are only visible if this.expanded is true.
     *
     * @returns {Object} - Filtered statuses.
     */
    mainStatuses() {
      if (!this.currentTrackingInformation) {
        return [];
      }

      return this.currentTrackingInformation.history.filter((status) => {
        return status.main === null ? this.expanded : true;
      });
    },

    /**
     * Adds "fake" future statuses if there's no final status.
     *
     * @returns {Object} - Future statuses.
     */
    futureStatuses() {
      if (this.currentStatus.final === true) {
        return;
      }
      const statuses = [];

      if (this.currentStatus.current < config.STATUS_ACCEPTED) {
        statuses.push({
          main: 'accepted',
          description: this.$t('future_statuses.accepted'),
        });
      }

      if (this.currentStatus.current < config.STATUS_SORTING) {
        statuses.push({
          main: 'sorting',
          description: this.$t('future_statuses.sorting'),
        });
      }

      if (this.currentStatus.current < config.STATUS_DISTRIBUTION) {
        statuses.push({
          main: 'distribution',
          description: this.$t('future_statuses.distribution'),
        });
      }

      if (this.currentStatus.current < config.STATUS_DELIVERED) {
        statuses.push({
          main: 'delivered',
          description: this.$t('future_statuses.delivered'),
          final: true,
        });
      }

      return statuses;
    },
  },
  methods: {
    generateKey(status) {
      const time = status.time ? status.time.toLowerCase().replace(/\s/, '') : 'undefined';
      const code = status.description;
      return `id_${time}_${code}`;
    },
  },
};
</script>
