<template>
  <div class="card contact">
    <div
      class="card-body contact-information"
      :class="{
        'has-logo': hasLogo,
      }">
      <BrandingCompanyLogo />

      <div class="sender">
        <h4 v-t="'track_trace.sender'" />
        <SenderAddress />
      </div>

      <div class="recipient">
        <h4 v-t="'track_trace.recipient'" />
        <AddressBlock :data="currentTrackingInformation.recipient" />
        <a
          v-if="currentTrackingInformation.pickup"
          v-t="{
            path: 'shipment_options.pickup_location',
            args: { pickup_location: $t(`carriers.${currentTrackingInformation.carrier.name}.pickup_location`) },
          }"
          href="#"
          :class="`link-${currentTrackingInformation.carrier.name}`"
          @click.prevent="contactExpanded = true" />
      </div>
    </div>

    <transition-expand>
      <div v-show="contactExpanded">
        <div
          class="card-body details">
          <div>
            <div v-if="currentTrackingInformation.pickup">
              <h4 v-t="'shipment_options.pickup_location_details'" />
              <b v-text="currentTrackingInformation.pickup.location_name" />
              <br>
              <AddressBlock :data="currentTrackingInformation.pickup" />

              <CButton
                v-t="'shipment_options.pickup_location_maps_url'"
                :href="mapsURL"
                tag="a"
                target="_blank" />
            </div>

            <hr v-if="hasDetails && currentTrackingInformation.pickup">

            <div v-if="hasDetails">
              <ShipmentOptionsAlerts />
              <template v-if="options.label_description">
                {{ $t('shipment_options.label_description') }}:
                {{ options.label_description }}
              </template>
            </div>
          </div>
          <div v-if="currentTrackingInformation.pickup">
            <div class="maps-wrapper">
              <iframe
                :src="mapsEmbedURL"
                width="100%"
                height="250"
                frameborder="0"
                style="border:0" />
            </div>
          </div>
        </div>
      </div>
    </transition-expand>
    <CardToggle
      v-if="canExpand"
      :toggle.sync="contactExpanded" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AddressBlock from '@/components/AddressBlock';
import BrandingCompanyLogo from '@/components/branding/BrandingCompanyLogo';
import CButton from '@/components/CButton';
import CardToggle from '@/components/CardToggle';
import SenderAddress from '@/components/tracktrace/SenderAddress';
import ShipmentOptionsAlerts from '@/components/tracktrace/ShipmentOptionsAlerts';
import smoothReflow from 'vue-smooth-reflow';

export default {
  name: 'TrackTraceContact',
  components: {
    BrandingCompanyLogo,
    SenderAddress,
    ShipmentOptionsAlerts,
    CButton,
    CardToggle,
    AddressBlock,
  },

  mixins: [smoothReflow],

  data() {
    return {
      contactExpanded: false,
      hasLogo: true,
    };
  },

  computed: {
    ...mapState(['branding']),
    ...mapGetters([
      'currentTrackingInformation',
    ]),

    mapsEmbedURL() {
      return `https://www.google.com/maps/embed/v1/place?key=${this.$config.googleMapsAPIkey}&q=${this.mapsQuery()}`;
    },

    mapsURL() {
      return `https://www.google.com/maps/search/?api=1&query=${this.mapsQuery('+')}`;
    },

    canExpand() {
      return this.hasDetails || this.currentTrackingInformation.pickup;
    },

    options() {
      return this.currentTrackingInformation.options;
    },

    hasDetails() {
      return this.options && (this.options.shown_options.length || this.options.label_description);
    },
  },

  mounted() {
    this.$smoothReflow();
  },

  methods: {
    mapsQuery(separator = ',') {
      let query = '';
      const rp = (str) => {
        return str.replace(/\s/, '+');
      };

      query += rp(this.currentTrackingInformation.pickup.location_name) + separator;
      query += rp(this.currentTrackingInformation.pickup.street) + separator;
      query += rp(this.currentTrackingInformation.pickup.postal_code) + separator;
      query += rp(this.currentTrackingInformation.pickup.number);

      return encodeURIComponent(query);
    },
  },
};
</script>
