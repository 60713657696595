<template>
  <ErrorWrapper
    :error="error"
    retry
    error-class="text-center">
    <div class="card text-center">
      <div class="card-body d-flex py-5">
        <div class="m-auto">
          <SkeletonLoader v-if="loadingTrackingInformation">
            <h4
              v-skeleton="20"
              class="mb-3" />
            <CButton
              v-skeleton="20"
              variant="primary"
              :loading="loading" />
          </SkeletonLoader>
          <template v-else>
            <h4
              v-t="'track_trace.returns_title'"
              class="mb-3 tw-text-primary-500" />

            <CButton
              v-if="canCreateRelatedReturn"
              variant="primary"
              :loading="loading"
              @click="createRelatedReturn">
              {{ $t('track_trace.returns_button') }}
            </CButton>
            <template v-else>
              <p v-t="'track_trace.returns_disabled_description'" />
              <CButton
                variant="primary"
                class="mt-2"
                disabled>
                {{ $t('track_trace.returns_button') }}
              </CButton>
            </template>
          </template>
        </div>
      </div>
    </div>
  </ErrorWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ApiError } from '@/services/ApiError';
import CButton from '@/components/CButton';
import ErrorWrapper from '@/components/ErrorWrapper';
import SkeletonLoader from '@/components/SkeletonLoader';
import { isDemo } from '@/services/functions/isDemo';

export default {
  name: 'TrackTraceReturns',
  components: { SkeletonLoader, ErrorWrapper, CButton },
  data() {
    return {
      loading: false,
      error: null,
    };
  },

  computed: {
    ...mapState(['loadingTrackingInformation']),
    ...mapGetters(['currentTrackingInformation']),

    canCreateRelatedReturn() {
      return this.currentTrackingInformation.returnable;
    },
  },

  methods: {
    async createRelatedReturn() {
      this.icon = null;
      this.loading = true;

      try {
        const response = await this.$store.dispatch('POST_RELATED_RETURN_SHIPMENT');

        await this.$router.push({
          name: isDemo() ? 'ReturnsDemo' : 'Returns',
          params: {
            hash: response.hash,
          },
        });
      } catch (e) {
        this.error = new ApiError(e);
      }

      this.loading = false;
    },
  },
};
</script>
