<template>
  <Paragraph>
    <SkeletonLoader v-if="loading">
      <span v-skeleton="[13, 15, 11]" />
    </SkeletonLoader>

    <template v-else>
      <template v-if="person">
        {{ person }}<br>
      </template>

      <template v-if="company">
        {{ company }}<br>
      </template>

      <template v-if="fullStreet">
        {{ fullStreet }}<br>
      </template>

      <template v-if="streetAdditionalInfo">
        {{ streetAdditionalInfo }}<br>
      </template>

      <template v-if="postalCodeCity">
        {{ postalCodeCity }}<br>
      </template>

      <template v-if="country">
        <br>{{ country }}
      </template>
    </template>
  </Paragraph>
</template>

<script>
import Paragraph from '@/components/Paragraph';
import SkeletonLoader from '@/components/SkeletonLoader';

export default {
  name: 'AddressBlock',
  components: { Paragraph, SkeletonLoader },
  props: {
    data: {
      type: Object,
      default: null,
    },

    loading: {
      type: Boolean,
    },
  },

  computed: {
    person() {
      return this.data.person;
    },

    company() {
      return this.data.company || this.data.name;
    },

    fullStreet() {
      // Number is optional to allow for single line addresses like from the `translation` table.
      if (this.data.street) {
        return `${this.data.street} ${this.data.number || ''} ${this.data.number_suffix || ''} ${this.data.box_number || ''}`.trim();
      }

      return null;
    },

    streetAdditionalInfo() {
      return this.data.street_additional_info;
    },

    country() {
      return this.data.country;
    },

    postalCodeCity() {
      if (this.data.postal_code || this.data.city) {
        return `${this.data.postal_code || ''} ${this.data.city || ''}`.trim();
      }

      return null;
    },
  },
};
</script>
