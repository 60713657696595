<template>
  <Loader
    v-if="loadingTrackingInformation || loadingFields"
    type="full-screen" />

  <DelayedTransitionGroup
    v-else
    v-test="'grid'"
    :class="{
      'track-trace--has-returns': hasReturnsWidget,
    }"
    appear
    class="grid track-trace">
    <TrackTraceStatus
      key="tt-status"
      v-test="'tt-status'"
      class="text-center track-trace__status" />

    <TrackTraceInfo
      key="tt-info"
      v-test="'tt-info'"
      class="track-trace__info" />

    <TrackTraceContact
      key="tt-contact"
      v-test="'tt-contact'"
      class="track-trace__contact" />

    <TrackTraceSocial
      v-if="hasSocialWidget"
      key="tt-social"
      v-test="'tt-social'"
      class="track-trace__social" />

    <TrackTraceBanner
      v-if="showBanner"
      key="tt-banner"
      v-test="'tt-banner'"
      class="track-trace__banner" />

    <TrackTraceReturns
      v-if="hasReturnsWidget"
      key="tt-returns"
      v-test="'tt-returns'"
      :style="{
        height: returnsHeight,
        // Put returns next to review when banner is present
        gridRowStart: showBanner ? 4 : null,
        gridColumnStart: returnsGridColumnStart,
      }"
      class="track-trace__returns" />

    <TrackTraceReview
      v-if="hasReviewWidget"
      key="tt-review"
      v-test="'tt-review'"
      :style="{
        gridColumn: reviewGridColumn,
      }"
      class="text-center track-trace__review" />

    <TrackTraceFooter
      key="tt-footer"
      v-test="'tt-footer'"
      class="track-trace__footer" />
  </DelayedTransitionGroup>
</template>

<script>
import { BREAKPOINT_SM, getBreakpoint } from '@/services/functions/getBreakpoint';
import { mapGetters, mapState } from 'vuex';
import CompanyFooter from '@/components/CompanyFooter';
import MyParcelApi from '@/services/MyParcelApi';
import TrackTraceBanner from '@/components/tracktrace/TrackTraceBanner';
import TrackTraceContact from '@/components/tracktrace/TrackTraceContact';
import TrackTraceInfo from '@/components/tracktrace/TrackTraceInfo';
import TrackTraceReturns from '@/components/tracktrace/TrackTraceReturns';
import TrackTraceReview from '@/components/tracktrace/TrackTraceReview';
import TrackTraceSocial from '@/components/tracktrace/TrackTraceSocial';
import TrackTraceStatus from '@/components/tracktrace/TrackTraceStatus';
import { beforeEnterTrackTrace } from '@/router/route-guard/beforeEnterTrackTrace';
import { isDemo } from '@/services/functions/isDemo';
import throttle from 'lodash-es/throttle';

export default {
  name: 'TrackTrace',
  components: {
    TrackTraceReturns,
    TrackTraceReview,
    TrackTraceBanner,
    TrackTraceSocial,
    TrackTraceContact,
    TrackTraceInfo,
    TrackTraceStatus,
    TrackTraceFooter: CompanyFooter,
  },

  beforeRouteUpdate(to, from, next) {
    beforeEnterTrackTrace(to, from, next);
  },

  data() {
    return {
      isErsCountryCode: false,
      bannerHeight: '100%',
      returnsHeight: '100%',
      overrides: ['banner', 'social', 'returns', 'review'],
      breakpoint: BREAKPOINT_SM,
    };
  },

  computed: {
    ...mapState(['loadingTrackingInformation', 'loadingFields', 'branding']),
    ...mapGetters(['currentTrackingInformation', 'fields']),

    /**
     * @returns {boolean}
     */
    hasSocialWidget() {
      return !!this.fields
        && !!this.fields.social_widget
        && this.fields.social_widget.type !== 'none';
    },

    /**
     * @returns {boolean}
     */
    hasReviewWidget() {
      return !!this.currentTrackingInformation
        && !!this.currentTrackingInformation.status.final
        && !!this.fields.review_url;
    },

    /**
     * Show the banner only if an image, the action title or the action url are set.
     *
     * @returns {boolean}
     */
    showBanner() {
      const hasAction = !!this.fields && (!!this.fields.banner_action_title || !!this.fields.banner_action_url);
      const hasImage = !!this.branding && !!this.branding.banner;
      return hasAction || hasImage;
    },

    /**
     * @returns {boolean}
     */
    hasReturnsWidget() {
      const settingIsEnabled = !!this.branding && this.branding.allow_creating_related_returns === true;
      const isNationalCountryCode = this.$route.params.cc === this.$config.defaultCC;

      return settingIsEnabled && (this.isErsCountryCode || isNationalCountryCode);
    },

    /**
     * The span of the review widget depends on the existence of other elements.
     *
     * @returns {string}
     */
    reviewGridColumn() {
      const LEAVE_SPACE_FOR_RETURNS = 2;
      const FULL_WIDTH = 3;
      let span = 1;

      if (this.breakpoint > BREAKPOINT_SM) {
        span = this.showBanner && this.hasReturnsWidget
          ? LEAVE_SPACE_FOR_RETURNS
          : FULL_WIDTH;
      }

      return `span ${span}`;
    },

    /**
     * To put the returns widget next to review when needed.
     *
     * @returns {number}
     */
    returnsGridColumnStart() {
      const NEXT_TO_REVIEW = 3;
      let start = null;

      if (this.breakpoint > BREAKPOINT_SM && this.showBanner && this.hasReviewWidget) {
        start = NEXT_TO_REVIEW;
      }

      return start;
    },
  },

  mounted() {
    this.setBreakpoint();
    window.addEventListener('resize', throttle(this.setBreakpoint, 100));

    this.checkIfErsCountry();
  },

  methods: {
    /**
     * Get the breakpoint and save it in the state.
     */
    setBreakpoint() {
      this.breakpoint = getBreakpoint();
    },

    /**
     * Check if the current shipment is being sent to an ERS country and set this.isErsCountryCode accordingly.
     */
    async checkIfErsCountry() {
      if (isDemo()) {
        this.isErsCountryCode = true;
        return;
      }

      const countryCodes = await MyParcelApi.getErsCountryCodes();
      this.isErsCountryCode = Object.keys(countryCodes).includes(this.$route.params.cc);
    },
  },
};
</script>
