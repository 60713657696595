<template>
  <component
    :is="tag"
    class="loader loader__wrapper"
    v-bind="$attrs">
    <div class="d-flex">
      <b-spinner
        class="m-auto"
        :variant="variant"
        small />
    </div>
  </component>
</template>

<script>
import { hasVariantProp } from '@/mixins/hasVariantProp';

export default {
  name: 'LoaderSmall',
  mixins: [hasVariantProp],
  props: {
    tag: {
      default: 'div',
      type: String,
    },
  },
};
</script>
