<template>
  <Card
    variant="danger"
    :title="title">
    <Paragraph v-text="translatedMessage" />
  </Card>
</template>

<script>
import { ApiError } from '@/services/ApiError';
import Card from '@/components/Card';
import Paragraph from '@/components/Paragraph';

export default {
  name: 'ErrorCard',
  components: {
    Paragraph,
    Card,
  },

  props: {
    title: {
      type: String,
      default: 'api.error.title',
    },

    error: {
      type: ApiError,
      default: null,
    },

    text: {
      type: String,
      default: null,
    },
  },

  computed: {
    message() {
      return this.text ?? this.error.getMessage();
    },

    translatedMessage() {
      return this.$te(`api.error.${this.message}`)
        ? this.$t(`api.error.${this.message}`)
        : this.message;
    },
  },
};
</script>
