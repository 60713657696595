<template>
  <div class="card tw-text-primary-500">
    <a
      :href="fields.review_url"
      rel="noopener noreferrer"
      target="_blank"
      class="card-body px-0 py-5">
      <h2 v-t="'track_trace.rating_header'" />
      <Stars />
    </a>
  </div>
</template>

<script>
import Stars from '@/components/Stars';
import { mapGetters } from 'vuex';

export default {
  name: 'TrackTraceReview',
  components: { Stars },
  computed: {
    ...mapGetters(['fields']),
  },
};
</script>
