<template>
  <a
    v-test="{ id: 'card-toggle' }"
    href="#"
    class="card-footer card-toggle hover:tw-text-primary-600 tw-duration-100 tw-text-primary-500 tw-transition-colors"
    @click.prevent="toggleButton">
    <span v-show="!toggle">
      {{ $t(openText) }}
      <span
        v-if="badge"
        class="badge badge-pill badge-primary"
        v-text="'!'" />
      <i class="i-arrow-down" />
    </span>
    <span v-show="toggle">
      {{ $t(closeText) }} <i class="i-arrow-up" />
    </span>
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CardToggle',
  props: {
    toggle: {
      type: Boolean,
    },

    openText: {
      default: 'global.expand',
      type: String,
    },

    closeText: {
      default: 'global.collapse',
      type: String,
    },

    badge: {
      type: Boolean,
    },
  },

  emits: ['update:toggle'],

  computed: {
    ...mapState(['branding']),
  },

  methods: {
    toggleButton() {
      this.$emit('update:toggle', !this.toggle);
    },
  },
};
</script>
