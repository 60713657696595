<template>
  <div class="card-header tw-bg-primary-500 tw-text-foreground">
    <h3
      v-if="!showDeliveryDate"
      v-test="'no-delivery-date'"
      v-t="{ path: 'home.tracking_heading', args: { shop: fields.company.name } }" />
    <template v-else>
      <template v-if="hasStartDate || hasEndDate">
        <h5 class="mt-0">
          <template v-if="currentStatus.final">
            {{ $t(`track_trace.delivery_moment`) }}
          </template>
          <template v-else-if="currentTrackingInformation.pickup">
            {{ $t(`track_trace.pickup_moment`) }}
          </template>
          <template v-else>
            {{ $t(`track_trace.delivery_moment_${currentTrackingInformation.delivery_moment_type || 'estimated'}`) }}
          </template>
        </h5>

        <template v-if="currentStatus.final">
          <h3
            class="m-0"
            v-text="formatDate(currentStatus.time)" />
          <h3
            v-t="{ path: 'track_trace.delivery_moment_at', args: { time: formatTime(currentStatus.time) } }"
            class="m-0" />
        </template>

        <template v-else-if="!hasEndDate">
          <h3 v-text="formatDate(startDate)" />
          <h3 v-text="formatTime(startDate)" />
        </template>

        <template v-else-if="hasStartDate && hasEndDate">
          <h3 v-text="formatDate(startDate)" />
          <h5
            v-t="{
              path: 'track_trace.delivery_moment_between',
              args: {
                start: formatTime(startDate),
                end: formatTime(endDate),
              },
            }" />
        </template>
      </template>

      <h5
        v-else
        v-t="'track_trace.delivery_unknown'" />
    </template>
  </div>
</template>

<script>
import carriers from '@/helpers/carriers';
import { mapGetters } from 'vuex';

export default {
  name: 'TrackTraceStatusHeader',
  computed: {
    ...mapGetters([
      'fields',
      'currentTrackingInformation',
    ]),

    /**
     * @returns {Object}
     */
    currentStatus() {
      return this.currentTrackingInformation.status;
    },

    /**
     * @returns {Object}
     */
    deliveryMoment() {
      return this.currentTrackingInformation ? this.currentTrackingInformation.delivery_moment : null;
    },

    carriers: () => carriers,

    /**
     * @returns {boolean}
     */
    hasDeliveryMoment() {
      return !!this.deliveryMoment;
    },

    /**
     * @returns {boolean}
     */
    hasEndDate() {
      return this.hasDeliveryMoment && this.deliveryMoment.end !== null;
    },

    /**
     * @returns {boolean}
     */
    hasStartDate() {
      return this.hasDeliveryMoment && this.deliveryMoment.start !== null;
    },

    /**
     * @returns {string}
     */
    startDate() {
      return this.hasStartDate ? this.deliveryMoment.start.date : '';
    },

    /**
     * @returns {string}
     */
    endDate() {
      return this.hasEndDate ? this.deliveryMoment.end.date : '';
    },

    /**
     * Bpost or non-NL/non-BE shipments don't have a reliable delivery date and moment, so we don't want to show it.
     *
     * @returns {boolean}
     */
    showDeliveryDate() {
      const isBpostShipment = this.currentTrackingInformation.carrier.name === carriers.BPOST;
      const isNlOrBeShipment = ['NL', 'BE'].includes(this.currentTrackingInformation.recipient.cc);

      return isNlOrBeShipment && !isBpostShipment;
    },
  },

  methods: {
    /**
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      const formattedDate = this.$options.filters.dayjs(date, 'date_format.full');
      return this.$options.filters.capitalize(formattedDate);
    },

    /**
     * @param {string} date
     * @returns {string}
     */
    formatTime(date) {
      return this.$options.filters.dayjs(date, 'date_format.time');
    },
  },
};
</script>
