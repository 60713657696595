<template>
  <div class="tw-w-full">
    <div
      v-if="$slots.loading && loading"
      v-show="loading">
      <slot
        name="loading" />
    </div>

    <img
      v-show="!loading"
      class="tw-m-auto tw-max-h-full tw-max-w-full"
      :alt="translatedAlt"
      :src="src"
      @dragstart.prevent=""
      @loadstart="onLoadStart"
      @load="onLoad"
      @error="onError">
  </div>
</template>

<script>
import { createTranslatablePropMixin } from '@/mixins/createTranslatablePropMixin';

export default {
  name: 'CImage',

  mixins: [
    createTranslatablePropMixin('alt'),
  ],

  props: {
    src: {
      type: String,
      required: true,
    },
  },

  emits: ['load', 'error'],

  data() {
    return {
      loading: false,
      loaded: false,
    };
  },

  methods: {
    onLoadStart() {
      this.loading = true;
    },

    onLoad() {
      this.loading = false;
      this.loaded = true;
      this.$emit('load');
    },

    onError() {
      this.loading = false;
      this.loaded = true;
      this.$emit('error');
    },
  },
};
</script>
