<template>
  <div class="status-wrapper">
    <div class="d-flex status-text tw-text-primary-500">
      <div class="d-flex m-auto">
        <div class="my-auto status-icon">
          <i :class="`i-${statusIcon}`" />
        </div>
        <div class="d-inline-block status-text">
          <h1 v-text="currentTrackingInformation.status.description" />
          <span class="h5">
            <a
              :href="currentTrackingInformation.link_tracktrace"
              rel="noopener noreferrer"
              target="_blank"
              v-text="$route.params.barcode || currentTrackingInformation.barcode" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrackTraceStatus',
  computed: {
    ...mapGetters(['currentTrackingInformation']),

    statusIcon() {
      let icon = 'help-circled';

      switch (this.currentTrackingInformation.status.main) {
        case 'registered':
          icon = 'accepted';
          break;
        case 'handed_to_carrier':
          icon = 'delivered';
          break;
        case 'sorting':
          icon = 'sorted';
          break;
        case 'distribution':
          icon = 'enroute';
          break;
        case 'delivered':
          icon = 'delivered';
          break;
      }
      return icon;
    },
  },
};
</script>
