<template>
  <Alert
    v-if="options.shown_options.length"
    class="py-2">
    <ul
      v-if="options.shown_options.length > 1"
      class="mb-0 pl-4">
      <li
        v-for="option in options.shown_options"
        :key="option"
        v-t="`shipment_options.${option}`" />
    </ul>
    <div
      v-for="option in options.shown_options"
      v-else
      :key="option"
      v-t="`shipment_options.${option}`" />
  </Alert>
</template>

<script>
import Alert from '@/components/Alert';
import { mapGetters } from 'vuex';

export default {
  name: 'ShipmentOptionsAlerts',
  components: { Alert },
  computed: {
    ...mapGetters(['currentTrackingInformation']),

    options() {
      return this.currentTrackingInformation.options;
    },
  },
};
</script>
