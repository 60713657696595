var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadingTrackingInformation || _vm.loadingFields)?_c('Loader',{attrs:{"type":"full-screen"}}):_c('DelayedTransitionGroup',{directives:[{name:"test",rawName:"v-test",value:('grid'),expression:"'grid'"}],staticClass:"grid track-trace",class:{
    'track-trace--has-returns': _vm.hasReturnsWidget,
  },attrs:{"appear":""}},[_c('TrackTraceStatus',{directives:[{name:"test",rawName:"v-test",value:('tt-status'),expression:"'tt-status'"}],key:"tt-status",staticClass:"text-center track-trace__status"}),_c('TrackTraceInfo',{directives:[{name:"test",rawName:"v-test",value:('tt-info'),expression:"'tt-info'"}],key:"tt-info",staticClass:"track-trace__info"}),_c('TrackTraceContact',{directives:[{name:"test",rawName:"v-test",value:('tt-contact'),expression:"'tt-contact'"}],key:"tt-contact",staticClass:"track-trace__contact"}),(_vm.hasSocialWidget)?_c('TrackTraceSocial',{directives:[{name:"test",rawName:"v-test",value:('tt-social'),expression:"'tt-social'"}],key:"tt-social",staticClass:"track-trace__social"}):_vm._e(),(_vm.showBanner)?_c('TrackTraceBanner',{directives:[{name:"test",rawName:"v-test",value:('tt-banner'),expression:"'tt-banner'"}],key:"tt-banner",staticClass:"track-trace__banner"}):_vm._e(),(_vm.hasReturnsWidget)?_c('TrackTraceReturns',{directives:[{name:"test",rawName:"v-test",value:('tt-returns'),expression:"'tt-returns'"}],key:"tt-returns",staticClass:"track-trace__returns",style:({
      height: _vm.returnsHeight,
      // Put returns next to review when banner is present
      gridRowStart: _vm.showBanner ? 4 : null,
      gridColumnStart: _vm.returnsGridColumnStart,
    })}):_vm._e(),(_vm.hasReviewWidget)?_c('TrackTraceReview',{directives:[{name:"test",rawName:"v-test",value:('tt-review'),expression:"'tt-review'"}],key:"tt-review",staticClass:"text-center track-trace__review",style:({
      gridColumn: _vm.reviewGridColumn,
    })}):_vm._e(),_c('TrackTraceFooter',{directives:[{name:"test",rawName:"v-test",value:('tt-footer'),expression:"'tt-footer'"}],key:"tt-footer",staticClass:"track-trace__footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }