<template>
  <a
    v-t="'track_trace.external_carrier_link'"
    class="carrier-link tw-text-primary-500"
    :href="link"
    rel="noopener noreferrer"
    target="_blank" />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ExternalCarrierLink',
  props: {
    link: {
      default: null,
      type: String,
    },
  },

  computed: {
    ...mapState(['branding']),
  },
};
</script>
