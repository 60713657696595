<template>
  <SkeletonLoader v-if="loadingFields">
    <IconButton
      v-for="i in 3"
      :key="`skeleton_social_${i}`"
      skeleton
      icon="" />
  </SkeletonLoader>

  <div v-else-if="Object.keys(socialLinks).length">
    <IconButton
      v-for="(link, name) in socialLinks"
      :key="`social-button__${name}`"
      :icon="name"
      tag="a"
      :href="link" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IconButton from '@/components/IconButton';
import SkeletonLoader from '@/components/SkeletonLoader';
import { socialPlatforms } from '@/helpers/socialPlatforms';

export default {
  name: 'SocialButtons',
  components: {
    SkeletonLoader,
    IconButton,
  },

  computed: {
    ...mapState(['loadingFields']),
    ...mapGetters(['fields']),

    /**
     * Map social links to their platform URLs, removing empty entries.
     *
     * @returns {Object}
     */
    socialLinks() {
      const links = this.fields.social_links || [];

      return Object.keys(links).reduce((acc, socialPlatform) => {
        if (!links[socialPlatform] || !links[socialPlatform].length) {
          return acc;
        }

        return {
          ...acc,
          [socialPlatform]: socialPlatforms[socialPlatform] + links[socialPlatform],
        };
      }, {});
    },
  },
};
</script>
