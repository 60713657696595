<template>
  <component
    :is="tag"
    v-test="'button'"
    role="button"
    :tabindex="0"
    class="tw-border-0 tw-duration-200 tw-font-display tw-inline-flex tw-no-underline tw-rounded-full tw-transition-colors tw-whitespace-nowrap"
    :class="[
      variantClass,
      {
        ['tw-py-3 tw-px-5']: !variant.startsWith('icon'),
        ['tw-cursor-pointer']: !isDisabled,
        ['animate-pulse tw-opacity-60']: isDisabled,
        ['tw-cursor-not-allowed']: disabled,
        ['tw-cursor-wait']: skeleton || loading,
      },
    ]"
    :aria-disabled="isDisabled"
    @click="isDisabled ? null : $emit('click', $event)">
    <SmoothReflow
      :options="{ property: ['width'] }"
      class="tw-flex">
      <div
        v-if="iconLeft"
        v-test="'icon-left'"
        class="tw-h-5 tw-inline-flex tw-mt-1 tw-my-auto tw-relative">
        <Transition
          appear
          name="fade-absolute">
          <i
            class="tw-flex tw-mr-2.5"
            :class="`i-${iconLeft}`" />
        </Transition>
      </div>

      <span class="tw-my-auto">
        <slot />
      </span>

      <div
        v-if="hasLoader || iconRight"
        v-test="'icon-right'"
        class="tw-h-5 tw-inline-flex tw-mt-1 tw-my-auto tw-relative">
        <Transition
          v-if="hasLoader"
          appear
          name="fade-absolute">
          <LoaderSmall
            v-show="loading"
            v-test="'loader'"
            tag="span"
            class="tw-ml-2.5"
            :variant="variant === 'light' ? 'primary' : 'white'" />
        </Transition>

        <Transition
          v-if="iconRight"
          appear
          name="fade-absolute">
          <i
            v-show="iconRight"
            class="tw-flex tw-ml-2.5"
            :class="`i-${iconRight}`" />
        </Transition>
      </div>
    </SmoothReflow>
  </component>
</template>

<script>
import LoaderSmall from '@/components/LoaderSmall';
import { buttonVariant } from '@/service/tailwind/variants/buttonVariant';
import { createNullPropValidator } from '@/helpers/validation/createNullPropValidator';
import { hasSizeProp } from '@/mixins/hasSizeProp';
import { hasVariantProp } from '@/mixins/hasVariantProp';
import { useTailwindProp } from '@/service/tailwind/useTailwindProp';

const {
  prop: variantProp,
  options: variantOptions,
} = useTailwindProp(buttonVariant);

export default {
  name: 'CButton',
  components: { LoaderSmall },
  mixins: [
    hasVariantProp,
    hasSizeProp,
  ],

  props: {
    variant: variantProp,

    loading: {
      default: null,
      validator: createNullPropValidator('boolean'),
    },

    skeleton: {
      type: Boolean,
    },

    iconLeft: {
      type: String,
      default: null,
    },

    iconRight: {
      type: String,
      default: null,
    },

    tag: {
      type: String,
      default: 'div',
    },

    /**
     * Whether the button should be disabled.
     */
    disabled: {
      type: Boolean,
    },
  },

  emits: ['click'],

  computed: {
    /**
     * @returns {boolean}
     */
    isDisabled() {
      return Boolean(this.skeleton || this.loading || this.disabled);
    },

    /**
     * @returns {boolean}
     */
    hasLoader() {
      return this.loading !== null;
    },

    /**
     * @returns {string | string[]}
     */
    variantClass() {
      return variantOptions[this.variant];
    },
  },
};
</script>
