<template>
  <Card
    centered
    variant="primary"
    class="tw-text-center"
    :title="fields ? fields.company.name : 'placeholder string'"
    :loading="loadingFields">
    <Skeleton
      :loading="loadingFields"
      centered>
      <template #default>
        <div class="tw-flex">
          <AddressBlock
            class="tw-flex-grow"
            :data="fields.company" />

          <div class="tw-flex-grow">
            <template v-if="fields.company.phone">
              {{ fields.company.phone }}
              <br>
            </template>
            <template v-if="fields.company.email">
              {{ fields.company.email }}
              <br>
            </template>
            <SocialButtons class="tw-mt-1" />
          </div>
        </div>
      </template>

      <template #skeleton>
        <div class="tw-flex">
          <AddressBlock
            class="tw-flex-grow"
            loading />

          <div class="tw-flex-grow">
            <span v-skeleton="[11, 24]" />
            <SocialButtons />
          </div>
        </div>
      </template>
    </Skeleton>
  </Card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AddressBlock from '@/components/AddressBlock';
import Card from '@/components/Card';
import Skeleton from '@/components/Skeleton';
import SocialButtons from '@/components/SocialButtons';

export default {
  name: 'CompanyFooter',
  components: {
    AddressBlock,
    Card,
    Skeleton,
    SocialButtons,
  },

  computed: {
    ...mapState(['loadingFields']),
    ...mapGetters(['fields']),

    /**
     * Remove person and company from the address in the footer. Company is already displayed above the address block.
     *
     * @returns {Object}
     */
    address() {
      const { person, company, ...rest } = this.fields.company;
      return rest;
    },
  },
};
</script>
