<template>
  <div
    v-test="{ id: 'alert' }"
    :class="`alert-${variant}`"
    class="alert"
    role="alert">
    <h4
      v-if="title"
      class="alert-heading"
      v-text="translatedTitle" />
    <slot>
      <span v-text="translatedText" />
    </slot>
  </div>
</template>

<script>
import { createTranslatablePropMixin } from '@/mixins/createTranslatablePropMixin';
import { hasVariantProp } from '@/mixins/hasVariantProp';

export default {
  name: 'Alert',
  mixins: [
    hasVariantProp,
    createTranslatablePropMixin('title'),
    createTranslatablePropMixin('text'),
  ],
};
</script>
