<template>
  <div
    v-test="'card'"
    class="card grid-item--static-height text-center tw-bg-primary-500"
    v-bind="attrs"
    :style="bannerBackground">
    <component
      :is="bannerURL ? 'a' : 'div'"
      :href="bannerURL || null"
      :target="bannerURL ? '_blank' : null"
      class="card-body d-flex"
      :class="{
        'bg-filter': hasBannerImage && (fields.banner_action_title || hasButton),
        'text-decoration-none': !!bannerURL,
        'text-reset': !!bannerURL,
      }">
      <div
        class="m-auto"
        :class="{
          'text-white': hasBannerImage,
          'text-foreground': !hasBannerImage,
        }">
        <h3
          v-if="fields.banner_action_title"
          v-text="fields.banner_action_title" />
        <CButton
          v-if="hasButton"
          v-t="'track_trace.banner_button'"
          variant="light"
          size="lg" />
      </div>
    </component>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CButton from '@/components/CButton';
import config from '@/helpers/config';

export default {
  name: 'TrackTraceBanner',
  components: { CButton },

  /**
   * See computed->bannerBackground() for why this is here.
   */
  inheritAttrs: false,

  computed: {
    ...mapState(['branding']),
    ...mapGetters(['fields']),

    bannerURL() {
      let bannerURL = this.fields.banner_action_url;
      if (bannerURL === undefined || bannerURL === '') {
        return false;
      }

      // always prefix banner url with http
      if (!bannerURL.startsWith('http')) {
        bannerURL = `http://${bannerURL}`;
      }
      return bannerURL;
    },

    buttonEnabled() {
      return this.fields.banner_button_enabled !== '0';
    },

    hasBannerImage() {
      return !!this.branding.banner;
    },

    hasButton() {
      return this.buttonEnabled && this.bannerURL;
    },

    /**
     * Because the outer element can get its style property overridden with the generated transition-delay we have to
     * turn inheritAttrs off for this component and append the background image css to the inherited styles manually.
     *
     * @returns {Object}
     */
    bannerBackground() {
      const inheritedStyle = this.$getInheritedStyle(this);

      if (!this.hasBannerImage) {
        return inheritedStyle;
      }

      return {
        ...inheritedStyle,
        background: `url("${config.assetsURL}/${this.branding.banner}") no-repeat center/cover`,
      };
    },

    /**
     * Here the style attr is split from the inherited attrs so we can still v-bind these to the outer component to
     *  preserve the expected inheritance behavior.
     *
     * @returns {Object}
     */
    attrs() {
      const { style, ...newAttrs } = this.$attrs;

      return newAttrs;
    },
  },
};
</script>
