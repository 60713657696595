import { camelCase } from 'lodash-es/string';

/**
 * Creates a mixin which adds a string prop with a computed property which translates it, if possible.
 *
 * @param {String} propName
 *
 * @returns {Object}
 */
export function createTranslatablePropMixin(propName) {
  const computedPropName = camelCase(`translated_${propName}`);

  return {
    props: {
      [propName]: {
        type: String,
        default: null,
      },
    },

    computed: {
      /**
       * Check if the prop is translatable, if so, translate it. Other wise just return the prop value.
       *
       * @returns {String}
       */
      [computedPropName]() {
        return this.$te(this[propName]) ? this.$t(this[propName]) : this[propName];
      },
    },
  };
}
