<template>
  <div
    :class="{
      'track-trace__timeline__status--sub': isSubStatus,
      [`track-trace__timeline__status--${statusType}`]: statusType,
    }"
    class="status">
    <div class="d-flex date text-right whitespace-nowrap">
      <div
        v-if="status.time"
        class="ml-auto my-auto">
        <span v-text="$dayjs(status.time).format($t('date_format.short'))" /><br>
        <small v-text="$dayjs(status.time).format($t('date_format.time'))" />
      </div>
    </div>

    <div class="graphic">
      <span
        :class="!isFutureStatus ? `bg--${currentTrackingInformation.carrier.name}` : ''"
        class="dot" />

      <span
        v-if="!isFinalStatus || !isFinalStatus && isFutureStatus"
        :class="!isFutureStatus && !isCurrentStatus ? `bg--${currentTrackingInformation.carrier.name}` : ''"
        class="line" />
    </div>

    <div class="desc my-auto">
      <span v-text="status.description" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrackTraceTimelineStatus',
  props: {
    status: {
      default: null,
      type: Object,
    },

    statusType: {
      default: null,
      type: String,
    },
  },

  computed: {
    ...mapGetters(['currentTrackingInformation']),

    isCurrentStatus() {
      return this.statusType === 'current';
    },

    isFutureStatus() {
      return this.statusType === 'future';
    },

    isSubStatus() {
      return !this.isFutureStatus && this.status.main === null;
    },

    isFinalStatus() {
      return this.status.final === true;
    },
  },
};
</script>
