<script>
import ErrorCard from '@/views/ErrorCard';

export default {
  name: 'ErrorWrapper',
  functional: true,
  extends: ErrorCard,

  props: {
    /**
     * Classes to add to the error card.
     */
    errorClass: {
      type: [String, Array, Object],
      default: null,
    },
  },

  render(createElement, context) {
    if (context.props.error) {
      context.children = [
        createElement(ErrorCard, {
          props: context.props,
          class: context.props.errorClass,
        }),
      ];
    }

    context.data.props = {
      mode: 'out-in',
      name: 'grid',
    };

    return createElement('transition', context.data, context.children);
  },
};
</script>
