<template>
  <Skeleton
    v-show="hasLogo"
    :loading="loadingBranding"
    content-class="tw-flex">
    <template #default>
      <CImage
        v-if="!loadingBranding"
        :src="`${$config.assetsURL}/${branding.logo}${cacheBuster}`"
        :alt="altText"
        class="tw-h-32 tw-max-w-80 tw-mx-auto"
        @error="hasLogo = false" />
    </template>

    <template #skeleton>
      <div class="tw-animate-pulse tw-flex tw-w-full">
        <div class="tw-bg-black tw-bg-opacity-10 tw-h-32 tw-mx-auto tw-rounded-full tw-w-28" />
      </div>
    </template>
  </Skeleton>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CImage from '@/components/CImage';
import Skeleton from '@/components/Skeleton';

export default {
  name: 'BrandingCompanyLogo',
  components: { Skeleton, CImage },

  data() {
    return {
      // If the portal is loaded in an iframe, add cache busting. Done to show
      // changes instantly to the user editing the portal.
      cacheBuster: window === window.parent ? '' : `?${Date.now()}`,

      hasLogo: true,
    };
  },

  computed: {
    ...mapState(['branding', 'loadingFields', 'loadingBranding']),
    ...mapGetters(['fields']),

    /**
     * @returns {string}
     */
    altText() {
      const companyName = this.loadingFields ? null : this.fields.company.name;

      return `${companyName} logo`.trim();
    },
  },

  methods: {
    onLoad() {
      this.hasLogo = true;
    },
  },
};
</script>
